<template>
    <section class="card">
        <h2>Abonnement</h2>

        <CardList v-if="productPlans.length && subscription" :columns="productPlans.length">
            <Card v-for="(plan, index) in productPlans" :key="plan.ID"
                  :status-text="isActiveProductPlan(plan) ? 'Nuværende' : ''"
                  status-color="green"
                  :column="index + 1"
                  :readonly="isActiveProductPlan(plan) || isAboutToExpire"
                  @click="onProductPlan(plan)">
                <template #headline>{{ plan.FullName }}</template>
                <p>{{ plan.Description }}</p>
                <p>
                    <small>Pris pr. måned</small><br>
                    <strong class="larger">{{ formatNumberToPrice(plan.ProductPlanPricings[0].Price, true, 'DKK', true) }}</strong>
                </p>
                <ActionButton v-if="!isActiveProductPlan(plan)" label="Vælg" :small="true" :float="true"/>
            </Card>
        </CardList>
        <CardList v-else :columns="2">
            <Card v-for="index in 2" :key="index" :readonly="true" :column="index">
                <template #headline><Placeholder :width="45"/></template>
                <p><Placeholder :width="80"/></p>
                <p>
                    <small><Placeholder :width="25"/></small><br>
                    <strong class="larger"><Placeholder :width="20"/></strong>
                </p>
            </Card>
        </CardList>

        <template v-if="subscription.isActive">
            <template v-if="subscription?.SwitchToPlanID">
                <template v-if="isTrial">
                    <p v-if="subscription?.SwitchToPlanID">
                        <small>Abonnement står til at skifte</small><br>
                        <span v-html="subscriptionSwitchDescriptionAfterTrial"></span>
                    </p>
                    <p v-else>
                        <small>Tilmeld betalingskort</small><br>
                        Tilføj et betalingskort inden din <strong>prøveperiode</strong> udløber <strong>{{ formatDateToFnsDate(new Date(subscription.EndDate)) }}</strong>, for at bibeholde alle dine funktioner.
                    </p>
                </template>
                <p v-else>
                    <small>Abonnement står til at skifte</small><br>
                    Dit abonnement står til at skrifte til <strong>Selvbygger+ {{ getSwitchToPlan }}</strong> den <strong>{{ formatDateToFnsDate(new Date(subscription.EndDate)) }}</strong>.
                </p>
            </template>

            <template v-else-if="isAboutToExpire">
                <p>
                    <small>Abonnement afmeldt</small><br>
                    Dit abonnement er afmeldt og udløber <strong>{{ formatDateToFnsDate(new Date(subscription.EndDate)) }}</strong>.
                </p>
                <ActionButton v-if="isTrial" label="Genoptag medlemskab" :small="true" @click="reactivateTrial"/>
                <ActionButton v-else label="Genoptag medlemskab" :small="true" @click="isReactivateDialogOpen = true"/>
            </template>
        </template>
        <p v-else>
            <small>Intet medlemskab</small><br>
            Du har ikke et medlemskab. Vælg Bacis eller Pro ovenfor.
        </p>

    </section>

    <Dialog v-if="isConfirmActivateDialogOpen">
        <template #title>Tilføj abonnement</template>
        <template #default>
            <p v-html="confirmActivateDescription"></p>
        </template>
        <template #actions>
            <ActionButton icon="plus" label="Tilføj" :small="true" @click="onConfirmActivateButton" :disabled="waitingForResponse"/>
            <ActionButton icon="signout" label="Fortryd" :small="true" :secondary="true" @click="isConfirmActivateDialogOpen = false"/>
        </template>
    </Dialog>

    <Dialog v-if="isConfirmSwitchDialogOpen">
        <template #title>Skift abonnement</template>
        <template #default>
            <p v-html="confirmSwitchDescription"></p>
        </template>
        <template #actions>
            <ActionButton icon="refresh" label="Skift" :small="true" @click="onConfirmSwitchButton" :disabled="waitingForResponse"/>
            <ActionButton icon="signout" label="Fortryd" :small="true" :secondary="true" @click="isConfirmSwitchDialogOpen = false"/>
        </template>
    </Dialog>

    <Dialog v-if="isConfirmCancelDialogOpen">
        <template #title>Afmeld abonnement</template>
        <template #default>
            <p>Er du sikker på at vil afmelde dit abonnement?</p>
        </template>
        <template #actions>
            <ActionButton icon="trash" label="Afmeld" :small="true" :red="true" @click="cancelSubscription" :disabled="waitingForResponse"/>
            <ActionButton icon="signout" label="Fortryd" :small="true" :secondary="true" @click="isConfirmCancelDialogOpen = false"/>
        </template>
    </Dialog>

    <Dialog v-if="isReactivateDialogOpen">
        <template #title>Genoptag medlemskab</template>
        <template #default>
            <p>Det er desværre ikke muligt at genoptage medlemskab.</p>
            <p>Kontakt support på <a href="mailto:kontakt@selvbyggerplus.dk"><strong>kontakt@selvbyggerplus.dk</strong></a> for at få genoptaget dit medlemskab.</p>
        </template>
        <template #actions>
            <ActionButton icon="signout" label="Luk" :small="true" @click="isReactivateDialogOpen = false"/>
        </template>
    </Dialog>

    <Dialog v-if="isMissingPaymentDialogOpen">
        <template #title>Tilføj betalingskort</template>
        <template #default>
            <p>Du kan ikke ændre i dit abonnement før et betalingskort er tilføjet til din profil.</p>
        </template>
        <template #actions>
            <ActionButton icon="signout" label="Luk" :small="true" @click="isMissingPaymentDialogOpen = false"/>
        </template>
    </Dialog>

</template>

<script>
import ActionButton from '@/components/buttons/ActionButton.vue';
import CardList from '@/components/CardList.vue';
import Card from '@/components/Card.vue';
import { computed, onBeforeMount, ref } from 'vue';
import { httpsRequest } from '@/utils/firebase';
import { getProductPlanIDByLabel, getProductPlanLabelByID } from '@/utils/productPlanIDs';
import useState from '@/store/index';
import methods from '@/utils/methods';
import Placeholder from '@/components/Placeholder.vue';
import Dialog from '@/components/Dialog.vue';

export default {
    name: 'Subscriptions',
    components: {
        ActionButton,
        CardList,
        Card,
        Placeholder,
        Dialog,
    },
    props: {
        isTrial: {
            type: Boolean,
            default: false,
        },
        isAboutToExpire: {
            type: Boolean,
            default: false,
        },
        payment: {
            type: Object,
            default: null,
        },
    },
    setup(props) {
        const state = useState();
        const { subscription, updateSubscription, user } = state;
        const productPlans = ref([]);
        const isConfirmCancelDialogOpen = ref(false);
        const isReactivateDialogOpen = ref(false);
        const isConfirmSwitchDialogOpen = ref(false);
        const isConfirmActivateDialogOpen = ref(false);
        const isMissingPaymentDialogOpen = ref(false);
        const waitingForResponse = ref(false);

        const currentProductPlan = ref(null);
        const selectedProductPlan = ref(null);

        const confirmSwitchDescription = computed(() => {
            if (!selectedProductPlan.value) return '';

            let selected = getProductPlanLabelByID(selectedProductPlan.value.ID);
            selected = selected.charAt(0).toUpperCase() + selected.slice(1);
            let current = getProductPlanLabelByID(currentProductPlan.value.ID);
            current = current.charAt(0).toUpperCase() + current.slice(1);

            let message = '';
            if (props.isTrial) message = 'efter udløb af prøveperiode';
            else if (selectedProductPlan.value.ProductPlanPricings[0].Price < currentProductPlan.value.ProductPlanPricings[0].Price) message = 'efter udløb af nuværende abonnement';
            else message = 'med det samme, og du vil blive opkrævet differencen.';

            return `Du er ved at skrifte abonnement fra Selvbygger + ${ current } til <strong>Selvbygger + ${ selected }</strong><br><br>
                    Dit skifte vil træde i kraft ${ message }.<br><br>
                    Ønsker du at gennemføre?`;
        });

        const subscriptionSwitchDescriptionAfterTrial = computed(() => {
            let productPlan = getProductPlanLabelByID(subscription.value?.SwitchToPlanID);
            productPlan = productPlan.charAt(0).toUpperCase() + productPlan.slice(1);
            return `Dit abonnement står til at skrifte til <strong>Selvbygger+ ${ productPlan }</strong> efter udløb af prøveperiode.`;
        });

        const getSwitchToPlan = computed(() => {
            const productPlan = getProductPlanLabelByID(subscription.value?.SwitchToPlanID);
            return productPlan.charAt(0).toUpperCase() + productPlan.slice(1);
        });

        const confirmActivateDescription = computed(() => {
            if (!selectedProductPlan.value) return '';
            let selected = getProductPlanLabelByID(selectedProductPlan.value.ID);
            selected = selected.charAt(0).toUpperCase() + selected.slice(1);
            return `Du er ved at tilføje <strong>Selvbygger + ${ selected }</strong> som abonnment.`;
        });

        const fetchProductPlans = async () => {
            try {
                const request = await httpsRequest.httpsCallable('getUpodiProductPlans');
                const response = await request();
                productPlans.value = response.data;
            } catch (err) {
                console.log(err);
            }
        };

        onBeforeMount(() => {
            fetchProductPlans();
        });

        return {
            subscription,
            updateSubscription,
            productPlans,
            user,
            isConfirmCancelDialogOpen,
            isReactivateDialogOpen,
            isConfirmSwitchDialogOpen,
            selectedProductPlan,
            currentProductPlan,
            confirmSwitchDescription,
            subscriptionSwitchDescriptionAfterTrial,
            getSwitchToPlan,
            isConfirmActivateDialogOpen,
            confirmActivateDescription,
            isMissingPaymentDialogOpen,
            waitingForResponse,
        };
    },
    emits: ['updateChargingPrice'],
    mixins: [methods],
    methods: {
        async reactivateTrial() {
            try {
                const request = await httpsRequest.httpsCallable('updateSubscription');
                const response = await request({ subscriptionId: this.subscription.ID, body: { ...this.subscription, SwitchToPlanID: getProductPlanIDByLabel('pro') } });
                this.updateSubscription(response.data);
                this.isConfirmCancelDialogOpen = false;
            } catch (err) {
                console.log(err);
            }
        },
        isActiveProductPlan(productPlan) {
            const isActiveProductPlan = productPlan.ID === this.subscription.ProductPlanID;
            const isTrialAndProPlan = this.isTrial && productPlan.ID === getProductPlanIDByLabel('pro');
            const isActiveOrTrial = isActiveProductPlan || isTrialAndProPlan;

            if (isActiveOrTrial) {
                this.$emit('updateChargingPrice', productPlan?.ProductPlanPricings?.[0].Price || 0);
                this.currentProductPlan = productPlan;
            }

            return isActiveOrTrial;
        },
        async onProductPlan(plan) {
            this.selectedProductPlan = plan;
            if (this.subscription.isActive) this.isConfirmSwitchDialogOpen = true;
            else this.isConfirmActivateDialogOpen = true;
        },
        async switchSubscriptionPlan() {
            this.waitingForResponse = true;
            try {
                let request;
                let response;

                if (this.isTrial) {
                    request = await httpsRequest.httpsCallable('updateSubscription');
                    response = await request({ subscriptionId: this.subscription.ID, body: { ...this.subscription, SwitchToPlanID: this.selectedProductPlan.ID } });
                } else {
                    const selectedMonthlyPricing = this.selectedProductPlan.ProductPlanPricings[0].Price;
                    const currentMonthlyPricing = this.currentProductPlan.ProductPlanPricings[0].Price;

                    const action = currentMonthlyPricing > selectedMonthlyPricing ? 'renewal' : 'immediately';
                    request = await httpsRequest.httpsCallable('switchSubscriptionPlan');
                    response = await request({ subscriptionId: this.subscription.ID, productPlanId: this.selectedProductPlan.ID, action, endDate: this.subscription.EndDate });
                }

                this.updateSubscription(response.data);
                this.selectedProductPlan = null;
                this.isConfirmSwitchDialogOpen = false;
            } catch (err) {
                console.log(err);
            }
            this.waitingForResponse = false;
        },
        async createSubscription() {
            this.waitingForResponse = true;
            try {
                const request = await httpsRequest.httpsCallable('createSubscription');
                const response = await request({ customerId: this.user.ucid, productPlanId: this.selectedProductPlan.ID });
                this.updateSubscription(response.data);
                this.selectedProductPlan = null;
                this.isConfirmActivateDialogOpen = false;
            } catch (err) {
                console.log(err);
            }
            this.waitingForResponse = false;
        },
        async cancelSubscription() {
            this.waitingForResponse = true;
            try {
                let request;
                let response;

                if (this.isTrial) {
                    request = await httpsRequest.httpsCallable('updateSubscription');
                    response = await request({ subscriptionId: this.subscription.ID, body: { ...this.subscription, SwitchToPlanID: null } });
                } else {
                    request = await httpsRequest.httpsCallable('cancelSubscription');
                    response = await request(this.subscription.ID);
                }

                this.updateSubscription(response.data);
                this.isConfirmCancelDialogOpen = false;
            } catch (err) {
                console.log(err);
            }
            this.waitingForResponse = false;
        },
        onConfirmActivateButton() {
            if (!this.payment && !this.isTrial) {
                this.isConfirmActivateDialogOpen = false;
                this.isMissingPaymentDialogOpen = true;
            } else this.createSubscription();
        },
        onConfirmSwitchButton() {
            if (!this.payment && !this.isTrial) {
                this.isConfirmSwitchDialogOpen = false;
                this.isMissingPaymentDialogOpen = true;
            } else this.switchSubscriptionPlan();
        },
    },
};
</script>

<style scoped lang="less">

</style>
