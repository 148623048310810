<template>
    <ul :class="`card-list${ columns > 1 ? ' columns-' + columns : '' } ${ compact ? 'compact' : '' }`">
        <slot/>
    </ul>
</template>

<script>
export default {
    name: 'CardList',
    props: {
        columns: {
            type: Number,
            default: 1,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="less">
@import '../assets/css/variables';

ul {
    display: grid;
    grid-gap: var(--card-list-gap);
    grid-template-columns: minmax(0, 1fr);
    grid-auto-flow: dense;
    list-style: none;
    padding: 10px 0;
    margin: 0;

    @media @laptop-screen {
        &.columns-2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &.columns-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &.columns-4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    &.compact {
        gap: calc(var(--card-list-gap) / 2);
    }
}
</style>
