<template>
    <Main>
        <Profile/>
        <Subscriptions :is-trial="isTrial" :is-about-to-expire="isAboutToExpire" @update-charging-price="updateChargingPrice" :payment="payment"/>
        <Payment :is-trial="isTrial" :is-about-to-expire="isAboutToExpire" :price="price" @set-payment-card="setPaymentCard"/>

        <section>
            <h2>Version</h2>
            <p>1.0.2.7</p>
        </section>
    </Main>

    <ActionButton icon="signout" :red="true" :float="true" tooltip="Log ud" @click="signOut"/>

    <ModelRouterView/>
</template>

<script>
import Main from '@/components/Main.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import { auth } from '@/utils/firebase';
import useState from '@/store';
import ModelRouterView from '@/components/ModelRouterView.vue';
import methods from '@/utils/methods';
import Profile from '@/components/settings/Profile.vue';
import Subscriptions from '@/components/settings/Subscriptions.vue';
import Payment from '@/components/settings/Payment.vue';
import { computed, ref } from 'vue';
import { getProductPlanIDByLabel } from '@/utils/productPlanIDs';

export default {
    name: 'Settings',
    components: {
        Main,
        ActionButton,
        ModelRouterView,
        Profile,
        Subscriptions,
        Payment,
    },
    setup() {
        const state = useState();
        const { clearState, subscription } = state;
        const isCanceled = computed(() => !subscription.value.isActive);
        const price = ref(0);
        const payment = ref(null);
        const isTrial = computed(() => subscription.value?.ProductPlanID === getProductPlanIDByLabel('trial'));
        const isAboutToExpire = computed(() => {
            const expire = subscription.value?.Status === 1 && subscription.value?.StateCode === 6;
            const noSwitch = isTrial.value && !subscription.value?.SwitchToPlanID;
            return expire || noSwitch;
        });

        return { clearState, isTrial, isAboutToExpire, isCanceled, subscription, price, payment };
    },
    mixins: [methods],
    methods: {
        async signOut() {
            this.clearState();
            await auth.signOut();
            await this.$router.push({ name: 'login' });
        },
        updateChargingPrice(price) {
            this.price = price;
        },
        setPaymentCard(payment) {
            this.payment = payment;
        },
    },
};
</script>
<style scoped lang="less">
@import "src/assets/css/variables";

:deep(section) {
    &:not(:last-child) {
        margin-bottom: 7rem;
    }

    button {
        float: none;
        margin: 2rem 0 0;
    }

    p.columns {
        max-width: max-content;
        white-space: nowrap;

        > *:not(:first-child) {
            margin-left: 5rem;
        }
    }

    h2 {
        font-size: 3.6rem;
        line-height: 1.05;
        font-weight: var(--semibold-weight);
        margin: 0 0 2rem;
    }

    h3 {
        margin: 0 0 0.5rem 0;
        font-weight: var(--semibold-weight);
        font-size: 2.4rem;
        line-height: 1.1;
    }

    .card-list {
        margin-bottom: 0;

        @media @laptop-screen {
            max-width: max-content;
        }

        .card {
            border: 1px solid rgb(var(--semi-light-grey-color));
            background: rgb(var(--white-color));
            max-width: none;

            @media @laptop-screen {
                min-width: 320px;
            }

            button {
                pointer-events: none;
            }
        }
    }
}
</style>
