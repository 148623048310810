<template>
    <section class="card">
        <h2>Profil</h2>
        <p class="columns">
            <span>
                <small>Navn</small><br>
                {{ user.firstname }} {{ user.lastname }}
            </span>
            <span>
                <small>E-mail</small><br>
                {{ user.email }}
            </span>
            <span class="align-left">
                <small>Adgangskode</small><br>
                ******
            </span>
        </p>
        <ActionButton v-if="false" label="Rediger profil" :small="true" :secondary="true" @click="$router.push({ name: 'edit-profile' })"/>
    </section>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton.vue';
import useState from '@/store/index';

export default {
    name: 'Profile',
    components: {
        ActionButton,
    },
    setup() {
        const state = useState();
        const { user } = state;
        return { user };
    },
};
</script>

<style scoped lang="less">

</style>
