<template>
    <section class="card">
        <h2>Betaling</h2>
        <CardList v-if="isLoadingPayments || isTrial || payment" :columns="isTrial ? 2 : 1">
            <Card v-if="isTrial" status-text="Aktivt" status-color="green" :readonly="true"
                  :column="1">
                <template #headline>Første {{ trialMonthPeriod }} måneder gratis</template>
                <p>
                    <small>Udløber</small><br>
                    {{ formatDateToFnsDate(new Date(subscription.EndDate).getTime()) }}
                </p>
            </Card>
            <Card v-if="isLoadingPayments">
                <template #headline>
                    <Placeholder :width="80"/>
                </template>
                <p>
                    <small>
                        <Placeholder :width="30"/>
                    </small><br>
                    <Placeholder :width="15"/>
                </p>
            </Card>
            <Card v-else-if="payment" :status-text="payment.Issuer" status-color="grey" :column="2"
                  @click="updateCardInformation">
                <template #headline>{{ payment.FullName.toUpperCase() }}</template>
                <p>
                    <small>Udløber</small><br>
                    {{ formatDateToFnsDate(payment.ExpiryDate, 'LL/yy') }}
                </p>
                <ActionButton label="Skift" :small="true" :float="true"/>
            </Card>
        </CardList>

        <Placeholder v-if="isLoadingPayments" :width="80"/>
        <template v-else-if="!isAboutToExpire">
            <p v-if="isTrial && !payment">Tilføj et betalingskort inden den
                <strong>{{ formatDateToFnsDate(new Date(subscription.EndDate).getTime()) }}</strong>
                for at dit abonnement kører videre.</p>
            <p v-else-if="charging && payment">
                <small>Næste betaling</small><br>
                <template v-if="charging">
                    Din næste regning er på
                    <strong>{{ formatNumberToPrice(price, true, 'DKK', true) }}</strong> den
                    <strong>{{ formatDateToFnsDate(new Date(isTrial ? subscription.EndDate : charging.NextChargeDate))
                        }}</strong>.
                </template>
                <Placeholder v-else :width="100" unit="px"/>
            </p>
        </template>
        <ActionButton v-if="!payment" label="Tilføj betalingskort" :small="true" @click="updateCardInformation"/>
    </section>
</template>

<script>
import CardList from '@/components/CardList.vue';
import Placeholder from '@/components/Placeholder.vue';
import Card from '@/components/Card.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import { httpsRequest } from '@/utils/firebase';
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import useState from '@/store/index';
import methods from '@/utils/methods';
import { differenceInCalendarMonths } from 'date-fns';

export default {
    name: 'Payment',
    components: {
        CardList,
        Placeholder,
        Card,
        ActionButton,
    },
    props: {
        isTrial: {
            type: Boolean,
            default: false,
        },
        isAboutToExpire: {
            type: Boolean,
            default: false,
        },
        price: {
            type: Number,
            default: 0,
        },
    },
    setup(props, { emit }) {
        const state = useState();
        const { user, subscription } = state;
        const payment = ref(null);
        const charging = computed(() => subscription.value.SubscriptionCharges?.[0]);
        const isLoadingPayments = ref(true);
        const trialMonthPeriod = computed(() => {
            const startDate = new Date(subscription.value?.StartDate);
            const endDate = new Date(subscription.value?.EndDate);
            return differenceInCalendarMonths(endDate, startDate);
        });

        const fetchPaymentMethod = async () => {
            try {
                const request = await httpsRequest.httpsCallable('getUpodiPaymentMethod');
                const response = await request(user.value.ucid);
                const paymentDetails = response.data;
                if (paymentDetails) {
                    const cardNumber = paymentDetails.FullName.match(/.{1,4}/g);
                    const expiryDate = new Date(paymentDetails.ExpiryDate);
                    payment.value = {
                        ...paymentDetails,
                        FullName: cardNumber.join(' '),
                        ExpiryDate: expiryDate.setMonth(expiryDate.getMonth() - 1),
                    };
                    emit('setPaymentCard', paymentDetails);
                }
                isLoadingPayments.value = false;
            } catch (err) {
                console.log(err);
            }
        };

        onBeforeMount(() => {
            fetchPaymentMethod();
        });

        onMounted(async () => {
            if (!document.getElementById('paylike-script')) {
                const script = document.createElement('script');
                script.setAttribute('src', 'https://sdk.paylike.io/10.js');
                script.id = 'paylike-script';
                document.head.appendChild(script);
            }
        });

        return { charging, payment, user, subscription, isLoadingPayments, trialMonthPeriod };
    },
    mixins: [methods],
    emits: ['setPaymentCard'],
    methods: {
        payAndGetTransactionId() {
            const paylikePuclicKey = process.env.NODE_ENV === 'development' ? 'b30f80ef-5136-49fd-a381-5adf33b368fe' : '641a8ef4-938b-4995-b125-5185046f2077';
            return new Promise((resolve, reject) => {
                window.Paylike({ key: paylikePuclicKey }).pay(
                    {
                        title: this.payment ? 'Skift betalingskort' : 'Tilføj betalingskort',
                        text: 'Selvbygger+',
                        test: process.env.NODE_ENV === 'development',
                        unplanned: { merchant: true },
                    },
                    (err, result) => {
                        if (err) reject(err);
                        if (result?.transaction.id) resolve(result.transaction.id);
                        else reject(err);
                    },
                );
            });
        },
        async updateCardInformation() {
            const transactionId = await this.payAndGetTransactionId();
            const assignCardToCustomer = await httpsRequest.httpsCallable('assignCardToCustomer');
            const response = await assignCardToCustomer({
                transactionId,
                customerId: this.user.ucid,
                subscriptionId: this.subscription?.ID || null,
                productPlanId: this.subscription?.ProductPlanID || null,
                subscriptionEndDate: this.subscription?.EndDate || null,
            });

            const paymentDetails = response.data;
            const cardNumber = paymentDetails.FullName.match(/.{1,4}/g);
            const expiryDate = new Date(paymentDetails.ExpiryDate);
            this.payment = {
                ...paymentDetails,
                FullName: cardNumber.join(' '),
                ExpiryDate: expiryDate.setMonth(expiryDate.getMonth() - 1),
            };

            this.$emit('setPaymentCard', this.payment);
        },
    },
};
</script>

<style scoped lang="less">

</style>
