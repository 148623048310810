<template>
    <i></i>
</template>

<script>
import { h } from 'vue';

export default {
    name: 'Placeholder',
    setup(props) {
        return () => h(props.element, {
            class: ['placeholder', { inline: props.element === 'i' }, ...props.classes],
            style: [{ width: `${props.width}${props.unit}` }, { height: `${props.height}px` }, { borderRadius: `${props.borderRadius}px` }],
        });
    },
    props: {
        element: {
            type: String,
            default: 'i',
        },
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: null,
        },
        borderRadius: {
            type: Number,
            default: null,
        },
        classes: {
            type: Array,
            default: () => [],
        },
        unit: {
            type: String,
            default: '%',
        },
    },
};
</script>
