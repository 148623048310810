<template>
    <router-view v-slot="{ Component }">
        <transition name="modal">
            <component :is="Component"/>
        </transition>
    </router-view>
</template>

<script>
export default {
    name: 'ModelRouterView',
};
</script>
