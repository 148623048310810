<template>
    <li :class="`card column-${column}${readonlyClass}`" @click="$emit('click')">
        <span v-if="statusText" :class="`status-label ${statusColor}`">{{ statusText }}</span>
        <h3>
            <slot name="headline"/>
        </h3>
        <slot/>
    </li>
</template>

<script>
export default {
    name: 'Card',
    emits: ['click'],
    props: {
        statusText: {
            type: String,
            default: null,
        },
        statusColor: {
            type: String,
            default: null,
        },
        to: {
            type: String,
            default: null,
        },
        column: {
            type: Number,
            default: 1,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        readonlyClass() {
            return this.readonly ? ' readonly' : '';
        },
    },
};
</script>

<style lang="less">
@import '../assets/css/variables';

li.card {
    position: relative;
    background: rgb(var(--light-grey-color));
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all var(--time-transition) var(--cubic-transition);

    &:hover {
        transform: translate3d(0, -5px, 0);
        box-shadow: 0 5px 15px rgba(var(--grey-color), 30%);
    }

    @media @laptop-screen {
        ul[class*=columns-] & {
            &.column-1 {
                grid-column: 1;
            }

            &.column-2 {
                grid-column: 2;
            }

            &.column-3 {
                grid-column: 3;
            }

            &.column-4 {
                grid-column: 4;
            }
        }
    }

    &.readonly {
        pointer-events: none;
    }

    &.compact {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.with-description {
            p {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 45%;
            }

            time {
                order: 3;
                margin-left: auto;
            }
        }

        > * {
            margin: 0;
            white-space: nowrap;
        }

        span.status-label, p {
            display: none;
        }

        &.with-description {
            p {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 45%;
            }

            time {
                order: 3;
                margin-left: auto;
            }
        }
    }

    > span.status-label {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-50%);
    }

    h3 {
        font-size: 1.8rem;
        line-height: 1.1;
        font-weight: var(--semibold-weight);
        margin: 0;

        svg {
            font-size: 0.8em;
            margin-right: 1rem;
            vertical-align: text-top;
        }

        span.status-label {
            margin-left: 1em;
        }
    }
}
</style>
